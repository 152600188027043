import { put, takeLatest, call, all } from "redux-saga/effects";
import Geo from "../../api/geo";
import Spaces from "../../api/spaces";
import BuildingTypes from "../../api/buildingtypes";
import Features from "../../api/features";
import Ads from "../../api/ads";
import HomeTour from "../../api/hometour";
import PublishOnPortals from "../../api/publish-on-portals";
import * as actions from "../actions/actions";
import Referents from "../../api/referents";
import { trimEnd } from "lodash";

const {
  CITY_LIST,
  AD_POST,
  AD_PUT,
  FETCH_LOOKUPS,
  FETCH_LOOKUPS_SUCCESS,
  FETCH_LOOKUPS_ERROR,
  FETCH_AD_BY_ID_SUCCESS,
  FETCH_AD_BY_ID,
  DELETE_HOMETOUR,
  DELETE_HOMETOUR_SUCCESS,
  DELETE_HOMETOUR_ERROR,
  CHANGE_FIELD_VALUE,
  CREATE_AND_CHANGE_FIELD_VALUE,
  CREATE_AND_CHANGE_FIELD_VALUE_SUCCESS,
  CREATE_AND_CHANGE_FIELD_VALUE_ERROR,
  SET_MESSAGE,
  GET_MESSAGE,
  checker,
} = actions;

function* fileProcessing(payload) {
  const { files, setFileProgress, data, } = payload;
  const operations = files
    .map((file, index) => {
      switch (file.action) {
        case "upload":
          const { fieldset, field } = file;
          const fd = new FormData();
          const key = `${fieldset}-${field}-${index}`;
          fd.append(key, file, file.name);
          fd.append("id", data.id);
          fd.append("posizione", file.posizione);
          var options;

        
           options = {
            doctype: file.doctype,
            onUploadProgress: (e) => {
              // notify upload progress
              e.name = file.name;              
              setFileProgress(e, fieldset, field);
            },
          };
        
          // dispatch(adUpload(fd, options))
          return call(Ads.upload, fd, options);
 


        case "delete":
          return call(Ads.unload, file.id, file.doctype);

        case "set":
          return call(Ads.set, file.id, file.doctype, file);
        case "none":
          if(file.posizione !== null){

            return call(Ads.set, file.id, file.doctype, file);
          }
          break;
        default:
          return null;
      
      }
    })
    .filter((operation) => operation !== null);

  const responses = yield all(operations);
  const errors = responses
    .map((response) => checker(response))
    .filter((item) => !item[0])
    .map((item) => item[1]);
  if (errors.length > 0) {
    yield put(errors[0]);
    return false;
  }
  return true;
}

function* redirectToHome({ history }) {
  try {
    yield call(history.push, "/");
  } catch (error) {
    console.error(error);
  }
}

function* setMsgReferent(payload) {
  try {
    const{MessaggioId, Messaggio, Data,  ImmobileId, ReferenteId} = payload.payload.msg;

    yield call(Ads.setMessage({
      Id:0,
      Data,
      Messaggio,
      MessaggioId,
      ReferenteId,
      ImmobileId
    }));
  } catch (error) {
    console.error(error);
  }
}

function* getMsgReferent(payload) {
  try {

    const id = payload.payload;

    const msg = yield call(Ads.getMessageRef, id);

    console.log("todays", msg.data)
    return msg.data

    
  } catch (error) {
    console.error("errorGet",error);
  }
}

function* publishHomeTour(payload) {
  const { hometourData, setHometourProgress, data } = payload;
  const { headers, formData, tipologia, upload } = hometourData;
  let success = true,
    message;
  if (upload) {
    let response = yield call(HomeTour.publish, {
      headers,
      formData,
      setHometourProgress,
    });
    [success, message] = checker(response);

    if (success && response.data.status === "success") {
      const { id, url } = response.data;
      response = yield call(HomeTour.post, {
        id: data.id,
        reference: id,
        url,
        tipologia,
      });
      [success, message] = checker(response);
    }

    if (!success) {
      yield put(message);
    }
  }
  yield call(setHometourProgress, false); // finished
  return success;
}

function* publishOnPortals({ portalsData, data, setPortalsServerProcessing }) {
  let response,
    success = true,
    message;
  const { immoScout, homeGate } = portalsData;
  yield call(setPortalsServerProcessing, true);
  if (success && homeGate ) {
    response = yield call(
      PublishOnPortals.publish,
      { Portal: "HomeGate" },
      data.id
    );
    [success, message] = checker(response);
    if (!success) {
      yield put(message);
      return false;
    }
  }
  if (success && immoScout) {
    response = yield call(
      PublishOnPortals.publish,
      { Portal: "ImmoScout" },
      data.id
    );
    [success, message] = checker(response);
    if (!success) {
      yield put(message);
    }
  }

  yield call(setPortalsServerProcessing, false);
  return success;
}

function* formProcessing(action) {
  const { type, payload } = action;
  switch (type) {
    case AD_PUT:
    case AD_POST:
      if (yield fileProcessing(payload)){
        if (!(yield publishHomeTour(payload))) {
          break;
        }
        if (!(yield publishOnPortals(payload))) {
          break;
        }
        yield redirectToHome(payload);
      }
      break;

    default:
      break;
  }
}

//This saga will be fired on FETCH_FEATURES_API dispatch and will add feature to the list
function* fetchLookups() {
  const payload = {};
  const responseFeatures = yield Features.list();
  payload.features = responseFeatures.data;
  const responseVicinanze = yield Features.listVicinanze();
  payload.vicinanze = responseVicinanze.data;
  const responseCantons = yield Geo.cantons(navigator.language);
  payload.cantons = responseCantons.data;
  const responseRegions = yield Geo.regions();
  payload.regions = responseRegions.data;
  const responseSpaces = yield Spaces.list();
  payload.spaces = responseSpaces.data;
  const responseBuildingTypes = yield BuildingTypes.list();
  payload.buildingtypes = responseBuildingTypes.data;
 const responseReferents = yield Referents.list();
  payload.referents = responseReferents.data;
  if (
    responseSpaces.error ||
    responseBuildingTypes.error ||
    responseCantons.error ||
    responseRegions.error ||
    responseFeatures.error ||
    responseReferents.error ||
    responseVicinanze.error
  ) {
    yield put({ type: FETCH_LOOKUPS_ERROR, payload });
    console.log("ccc", payload);
  } else {
    yield put({ type: FETCH_LOOKUPS_SUCCESS, payload });
    console.log("ccc", payload);
  }
}

function* createAndChangeFieldValue(action) {
  const { payload } = action;
  const response = yield Geo.put_region(payload.value);
  if (response.error) {
    yield put({ type: CREATE_AND_CHANGE_FIELD_VALUE_ERROR, payload: response });
  } else {
    yield put({
      type: CREATE_AND_CHANGE_FIELD_VALUE_SUCCESS,
      payload: { ...payload, entry: response.data },
    });
    yield put({
      type: CHANGE_FIELD_VALUE,
      payload: { ...payload, value: response.data.id },
    });
  }
}

function* fetchAdById(action) {

  const { payload, history } = action; //ID given from the dispatch
  const response = yield Ads.get(payload);
  if (response.error) {
    yield call(history.push, "/404");
  } else {
    
    const response2 = yield Geo.cities(response.data.cantone.id);
    if (response2.error) {
      yield call(history.push, "/404");

    } else {

      yield put({
        type: CITY_LIST,
        payload: { ...response2 },
      });
      yield put({ type: FETCH_AD_BY_ID_SUCCESS, payload: response });
      yield call(history.push, `/ad/${payload}`);
    }
  }
}

function* deleteHomeTour(action) {
  // console.log([...formData], headers, action);
  let response,
    message,
    success = true;
  const { headers, id, history } = action.payload;
  response = yield call(HomeTour.deleteOnPortal, headers, id);
  // [success, message] = checker(response);
  //if (success) {
  response = yield call(HomeTour.delete, id);
  [success, message] = checker(response);
  //}
  if (success) {
    yield put({ type: DELETE_HOMETOUR_SUCCESS, payload: response });
    yield redirectToHome({ history });
  } else {
    yield put({ type: DELETE_HOMETOUR_ERROR, payload: response });
    yield put(message);
  }
}

function* mySaga() {
  yield takeLatest(FETCH_LOOKUPS, fetchLookups);
  yield takeLatest(CREATE_AND_CHANGE_FIELD_VALUE, createAndChangeFieldValue);
  yield takeLatest(FETCH_AD_BY_ID, fetchAdById);
  yield takeLatest([AD_POST, AD_PUT], formProcessing);
  yield takeLatest(DELETE_HOMETOUR, deleteHomeTour);
  yield takeLatest(SET_MESSAGE, setMsgReferent);
  yield takeLatest(GET_MESSAGE, getMsgReferent);
}

export default mySaga;
