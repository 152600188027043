import React, { Component, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormFeedback,
  FormText,
  Card,
  CardFooter,
  CardImg,
  CardBody,
  UncontrolledTooltip,
  Progress,
InputGroupButtonDropdown,
DropdownItem,
DropdownMenu,
DropdownToggle
} from "reactstrap";
import md5 from "md5";
import ThumbnailHelper from "../helpers/ThumbnailHelper";
import { fireEvent } from "@testing-library/react";

class FileUploader extends Component {
  state = {
    dropdownOpen: { },
  };

  toggleDropdown = (type) => {
    this.setState((prevState) => {
      const state = { ...prevState };
      state.dropdownOpen[type] = !state.dropdownOpen[type];

      return state;
    });
  };

  renderInput = (action) => {
    const {
      name,
      accept,
      onFilesSelected,
      onUploadStarted,
      label,
      disabled,
    } = this.props;
    return (
      <>
        <input
          accept={accept || "*/*"}
          disabled={disabled}
          className="d-none"
          type="file"
          name={name}
          multiple={true}
          onChange={onFilesSelected}
          ref={(r) => (this.choose = r)}
        />
        <Button color="primary" onClick={() => this.choose.click()}>
          {label}
        </Button>
        {onUploadStarted && (
          <Button color="primary" onClick={onUploadStarted}>
            Carica
          </Button>
        )}
      </>
    );
  };

  renderThumbnail = (file,files, action) => {
    const  type = file.id || file.name;
    const { onFileRemoved } = this.props;
    const { setPosition } = this.props;
var numImage=[];

var numImagesArray = files.map((f) => f.posizione)


    for(var i=0; i< Number(files.length); i++)
    {
    numImage.push(i+1);
    }
  
     const items = numImage.map((num) =>
     <DropdownItem disabled={numImagesArray ? numImagesArray.includes(num) : false} onClick={(e) => setPosition(file, num)}>
           {num}
     </DropdownItem>
     )
     
    if(file.principal === undefined){
    file.principal = false;
    }
    const {
      thumbnail,
      name,
      progress,
      url,
      doctype,
      mimeType,
      fileName,
      posizione
    } = file;
    if(file.posizione === null)
    {
      file.posizione = 1000
    }
    const hash = md5(name || fileName);
    const id = `filename-${hash}`;
    let progressBar = null;
    if (progress) {
      const percent = Math.round((progress.loaded / progress.total) * 100);
      progressBar = (
        <Progress color="info" animated value={percent}>
          {percent}%
        </Progress>
      );
    }
    const info =
      doctype !== "image" && mimeType
        ? ThumbnailHelper.createParameters({ type: mimeType })
        : null;
    return (
      <Col key={`thumbnail-${hash}`} xs={2} md={2}>
        <Card>
          {thumbnail && thumbnail.src && (
            <CardImg src={thumbnail.src} alt={name} />
          )}
          {!thumbnail && !info && url && fileName && (
            <CardImg src={url} alt={fileName} />
          )}
          {thumbnail && thumbnail.icon && (
            <CardBody>
              <i
                className={`fa ${thumbnail.icon} fa-5x ${
                  thumbnail.className ?? ""
                }`}
              />
            </CardBody>
          )}
          {!thumbnail && info && (
            <CardBody>
              <i className={`fa ${info.icon} fa-5x ${info.className}`} />
            </CardBody>
          )}
          <CardFooter>
            <div
              id={id}
              className="text-muted text-truncate"
              style={{ maxWidth: 200 }}
            >
              <div className="my-1">
                <small>{name || fileName}</small>
              </div>
              {progressBar || (
                <>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={(e) => onFileRemoved(e, file)}
                  >
                    <i className="fa fa-times"></i> elimina
                  </Button>
                 
                </>
              )}
               {/* <div style={{ marginTop: "20px" }}>
                    <input
                      type="radio"
                      name="principal"
                      value={id}
                      checked={principal}
                      onChange={(e) => setPrincipal(file)}
                    />
                    <label style={{ marginLeft: "20px" }}>Principale</label>
                  </div> */}
                  <span style={{display:"flex", marginTop:'10px'}}>
                  <InputGroupButtonDropdown
addonType={type}    isOpen={this.state.dropdownOpen[type]}
toggle={() => this.toggleDropdown(type)}
          >
            <DropdownToggle caret>{posizione === 1000? null : posizione}</DropdownToggle>
            <DropdownMenu>
            <DropdownItem onClick={(e) => setPosition(file, 1000)}>
             Nessuna
     </DropdownItem>
        {items}
            </DropdownMenu>
          </InputGroupButtonDropdown>
          <label style={{marginLeft:'10px',marginTop:'8px'}} >Posizione</label>
          </span>
            </div>
            {/* <UncontrolledTooltip placement="bottom" target={id}>
              {name || fileName}
            </UncontrolledTooltip> */}
          </CardFooter>
        </Card>
      </Col>
    );
  };

  render() {

    const { className, feedbacks, explanation, files, disabled } = this.props;

    const input = this.renderInput();
    return (
      <FormGroup className={className}>
        {files && (
          <Container fluid>
        
            <Row>
              
        {files.map((file) => this.renderThumbnail(file, files))}
           </Row>
  
          </Container>
        )}
        {!disabled &&
          feedbacks &&
          feedbacks.map((feedback, index) => {
            return (
              feedback && (
                <FormFeedback key={index} className="d-block">
                  {feedback}
                </FormFeedback>
              )
            );
          })}
        <FormText>{explanation}</FormText>
        <div className="text-right">{input}</div>
      </FormGroup>
    );
  }
}

export default FileUploader;
