import Security from "../../api/security";
import Ads from "../../api/ads";
import Geo from "../../api/geo";
import HttpStatusCode from "../../api/httpstatus";

export const REAUTHENTICATE_SUCCESS = "REAUTHENTICATE_SUCCESS";
export const REAUTHENTICATE_STARTED = "REAUTHENTICATE_STARTED";
export const REAUTHENTICATE_FAILURE = "REAUTHENTICATE_FAILURE";

export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_STARTED = "AUTHENTICATE_STARTED";
export const AUTHENTICATE_FAILURE = "AUTHENTICATE_FAILURE";

export const CITY_LIST_STARTED = "CITY_LIST_STARTED";
export const CITY_LIST = "CITY_LIST";
export const CITY_LIST_ERROR = "CITY_LIST_ERROR";

export const AD_PUT_STARTED = "AD_PUT_STARTED";
export const AD_PUT = "AD_PUT";
export const AD_PUT_ERROR = "AD_PUT_ERROR";

export const AD_POST_STARTED = "AD_POST_STARTED";
export const AD_POST = "AD_POST";
export const AD_POST_ERROR = "AD_POST_ERROR";

export const AD_UPLOAD_STARTED = "AD_UPLOAD_STARTED";
export const AD_UPLOAD = "AD_UPLOAD";
export const AD_UPLOAD_ERROR = "AD_UPLOAD_ERROR";

export const AD_LIST_STARTED = "AD_LIST_STARTED";
export const AD_LIST = "AD_LIST";
export const AD_LIST_ERROR = "AD_LIST_ERROR";

export const AD_GET_STARTED = "AD_GET_STARTED";
export const AD_GET = "AD_GET";
export const AD_GET_ERROR = "AD_GET_ERROR";

export const AD_DELETE_STARTED = "AD_DELETE_STARTED";
export const AD_DELETE = "AD_DELETE";
export const AD_DELETE_ERROR = "AD_DELETE_ERROR";

export const ERROR_DISPLAY = "ERROR_DISPLAY";
export const LOGOUT = "LOGOUT";

export const check = (dispatch, response) => {
  if (response.error) {
    if (response.status === HttpStatusCode.Unauthorized) {
      dispatch({ type: LOGOUT });
    } else {
      dispatch({
        type: ERROR_DISPLAY,
        payload: response,
      });
    }
    return false;
  }
  return true;
};

export const checker = (response) => {
  if (response.error) {
    if (response.status === HttpStatusCode.Unauthorized) {
      return [false, { type: LOGOUT }];
    } else {
      return [
        false,
        {
          type: ERROR_DISPLAY,
          payload: response,
        },
      ];
    }
  }
  return [true, null];
};

export const error = (e) => async (dispatch) => {
  dispatch({
    type: ERROR_DISPLAY,
    payload: e,
  });
};

export const authenticate = (username, password) => async (dispatch) => {
  dispatch({
    type: AUTHENTICATE_STARTED,
  });
  const payload = await Security.authenticate(username, password);
  if (payload.error) {
    dispatch({
      type: LOGOUT,
      payload,
    });
  } else {
    dispatch({
      type: AUTHENTICATE_SUCCESS,
      payload,
    });
  }
};

export const autosense = () => async (dispatch) => {
  dispatch({
    type: REAUTHENTICATE_STARTED,
  });
  const payload = await Security.autosense();
  if (payload.error) {
    dispatch({
      type: LOGOUT,
      payload,
    });
  } else {
    dispatch({
      type: REAUTHENTICATE_SUCCESS,
      payload,
    });
  }
};

export const cityList = ({ form, fieldset, field, value }) => async (
  dispatch
) => {
  dispatch({ type: CITY_LIST_STARTED });
  const payload = await Geo.cities(value);
  if (check(dispatch, payload)) {
    dispatch({
      type: CITY_LIST,
      payload: { ...payload, form, field, fieldset },
    });
  } else {
    dispatch({ type: CITY_LIST_ERROR, payload });
  }
};

export const adPut = ({
  data,
  files,
  setFileProgress,
  history,
  hometourData,
  setHometourProgress,
  portalsData,
  setPortalsServerProcessing,
}) => async (dispatch) => {
  dispatch({ type: AD_PUT_STARTED });
  if(data.tour360.length > 0){
    data.tour360url = data.tour360[0].url;
    data.tour360Visibility = true;
  }
  const payload = await Ads.put(data);
  if (check(dispatch, payload)) {
    dispatch({
      type: AD_PUT,
      payload: {
        ...payload,
        files,
        setFileProgress,
        history,
        hometourData,
        setHometourProgress,
        portalsData,
        setPortalsServerProcessing,
      },
    });
  } else {
    dispatch({
      type: AD_PUT_ERROR,
      payload,
    });
  }
};

export const adPost = ({
  data,
  files,
  setFileProgress,
  history,
  hometourData,
  setHometourProgress,
  portalsData,
  setPortalsServerProcessing,
  ricercheFiltrate
}) => async (dispatch) => {

  dispatch({ type: AD_POST_STARTED });

  const payload = await Ads.post(data);
 
 if(ricercheFiltrate.length > 0){
  await Ads.setNewsletter(payload?.data?.id, ricercheFiltrate?.length);
 }
  if (check(dispatch, payload)) {
    dispatch({
      type: AD_POST,
      payload: {
        ...payload,
        files,
        setFileProgress,
        history,
        hometourData,
        setHometourProgress,
        portalsData,
        setPortalsServerProcessing,
      },
    });
  } else {
    dispatch({
      type: AD_POST_ERROR,
      payload,
    });
  }
};

export const adGet = (id) => async (dispatch) => {
  dispatch({ type: AD_GET_STARTED, payload: { id } });
  const payload = await Ads.get(id);
  if (check(dispatch, payload)) {
    dispatch({
      type: AD_GET,
      payload,
    });
  } else {
    dispatch({
      type: AD_GET_ERROR,
      payload,
    });
  }
};

export const adList = (params) => async (dispatch) => {
  dispatch({ type: AD_LIST_STARTED, payload: { params } });
  const payload = await Ads.list(params);
  if (check(dispatch, payload)) {
    dispatch({
      type: AD_LIST,
      payload,
    });
  } else {
    dispatch({
      type: AD_LIST_ERROR,
      payload,
    });
  }
};

export const adDelete = (id) => async (dispatch) => {
  dispatch({ type: AD_DELETE_STARTED, payload: { id } });
  const payload = await Ads.delete(id);
  if (check(dispatch, payload)) {
    dispatch({
      type: AD_DELETE,
      payload,
    });
  } else {
    dispatch({
      type: AD_DELETE_ERROR,
      payload,
    });
  }
};

export const adUpload = (data, options) => async (dispatch) => {
  dispatch({ type: AD_UPLOAD_STARTED, payload: { data, options } });
  console.log("pp", data);
  const payload = await Ads.upload(data, options);
  if (check(dispatch, payload)) {
    dispatch({
      type: AD_UPLOAD,
      payload,
    });
  } else {
    dispatch({
      type: AD_UPLOAD_ERROR,
      payload,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
