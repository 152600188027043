import _ from "lodash";
import Formatter from "../../helpers/Formatter";

import {
  CITY_LIST,
  AD_GET,
  AD_LIST,
  AD_POST,
  AD_PUT,
  AD_DELETE,
  AD_UPLOAD,
  VALIDATED_FIELD_VALUE,
  AUTHENTICATE_SUCCESS,
  addFeatureToFormList,
  removeVicinanzaFromList,
  addVicinanzeToFormList,
  removeFeatureFromList,
  FETCH_LOOKUPS_SUCCESS,
  CREATE_AND_CHANGE_FIELD_VALUE_SUCCESS,
  FETCH_AD_BY_ID_SUCCESS,
  CLEAR_FORM_DATA,
  PUBLISH_ON_PORTALS_VALUE_CHANGED,
} from "../actions/actions";

import { initialState } from "./app.initial.state.js";

const formsReducer = (state = initialState.forms, action) => {
  const clone = _.cloneDeep(state);
  const { payload } = action;
  const { form, fieldset, field, value, data } = payload || {};
  switch (action.type) {
    case AD_UPLOAD:
      if (!clone.ad.images) clone.ad.images = {};
      if (!clone.ad.images.images) clone.ad.images.images = [];
      clone.ad.images.images.push(payload.data);
      return clone;

    case AD_POST:
      // recupero l'ID dell'immobile
      clone.ad.id = payload.data.id;
      return clone;

    case AD_PUT:
    case AD_GET:
    case AD_LIST:
    case AD_DELETE:
      return clone;

    case CITY_LIST:
      clone.formDefaultValues.cities = data;
      return clone;

    case VALIDATED_FIELD_VALUE:
      if (!clone[form]) {
        clone[form] = {};
      }
      if (!clone[form][fieldset]) {
        clone[form][fieldset] = {};
      }
      clone[form][fieldset][field.name] = Formatter.format(value, field.type);
      if (field.name === "cantone") {
        delete clone[form][fieldset].citta;
      }
      return clone;

    case AUTHENTICATE_SUCCESS:
      clone.login.credentials = {
        username: "",
        password: "",
      };
      return clone;

    case FETCH_LOOKUPS_SUCCESS:
      clone.formDefaultValues = { ...clone.formDefaultValues, ...payload };
      return clone;

    case CREATE_AND_CHANGE_FIELD_VALUE_SUCCESS:
      clone.formDefaultValues.regions = [
        ...clone.formDefaultValues.regions,
        payload.entry,
      ];
      return clone;

    case addFeatureToFormList:
      if (!clone.ad.features) clone.ad.features = {};
      if (!clone.ad.features.caratteristiche)
        clone.ad.features.caratteristiche = [payload];
      else
        clone.ad.features.caratteristiche = [
          ...clone.ad.features.caratteristiche,
          payload,
        ];
      return clone;

      case addVicinanzeToFormList:
        if (!clone.ad.vicinanze) clone.ad.vicinanze = {};
        if (!clone.ad.vicinanze.vicinanze)
          clone.ad.vicinanze.vicinanze = [payload];
        else
          clone.ad.vicinanze.vicinanze = [
            ...clone.ad.vicinanze.vicinanze,
            payload,
          ];
        return clone;
    //Removing item based on the index passed from the action
    case removeFeatureFromList:
      const removedItem = state.ad.features.caratteristiche.filter(
        (item, index) => {
          return index !== payload;
        }
      );
      clone.ad.features.caratteristiche = removedItem;
      return clone;

        case removeVicinanzaFromList:
      const removedVicItem = state.ad.vicinanze.vicinanze.filter(
        (item, index) => {
          return index !== payload;
        }
      );
      clone.ad.vicinanze.vicinanze = removedVicItem;
      return clone;

    case CLEAR_FORM_DATA:
      return {
        ...state,
        ad: {
          ...state.ad,
          ...initialState.forms.ad,
        },
        formDefaultValues: {
          ...state.formDefaultValues,
          cities: [],
        },
      };

    case FETCH_AD_BY_ID_SUCCESS:


      const features = payload.data.immobiliCaratteristiche.map((item) => {
        return {
          caratteristicaId: item.caratteristicaId,
          caratteristica: item.caratteristica,
          dettagli: item.dettagli,
          prezzo: item.prezzo,
          quantita: item.quantita,
        };
      });

      const vicinanze = payload.data.immobiliVicinanze.map((item) => {
        return {
          vicinanzaId: item.vicinanzaId,
          vicinanza: item.vicinanza,
          distanza: item.distanza,
        };
      });

      return {
        ...state,
        ad: {
          ...state.ad,
          //Fetched
          id: payload.data.id,
          info: {
            riferimento: payload.data.riferimento,
            titolo: payload.data.titolo,
          },
          images: {
            images: payload.data.immagini,
          },
          documents: {
            documents: payload.data.documenti,
          },
          tour360: {
            tour360: payload.data.tour360,
            tour360url: payload.data.tour360url,
          },
          features: {
            caratteristiche: features,
          },
          vicinanze: {
           vicinanze: vicinanze,
          },
          state: {
            publishOnDisplay: payload.data.publishOnDisplay,
            visibilita: payload.data.visibilita,
            note: payload.data.note,
          },
          real_estate: {     
            tipologia: payload.data.tipologia.id,
            contratto: payload.data.contratto,
            descrizione: payload.data.descrizione,
            locali: payload.data.locali.id,
            metratura: payload.data.metratura,
            piano: payload.data.piano,
            disponibilita: payload.data.disponibilita,
            regione: payload.data.regione.id, // TODO: dismiss this
            cantone: payload.data.cantone.id,
            citta: payload.data.citta.id,
            cap: payload.data.cap,
            indirizzo: payload.data.indirizzo,
            referente: payload.data.referente.id,
            superficieFondo: payload.data.superficieFondo,
            numeroAppartamenti: payload.data.numeroAppartamenti,
            latitude: payload.data.latitude,
            longitude: payload.data.longitude,
            pianiEdificio: payload.data.pianiEdificio,
            annoCostruzione: payload.data.annoCostruzione
          },
          amounts: {
            suRichiesta: payload.data.suRichiesta,
            pigione:  payload.data.pigione ,
            spese: payload.data.spese,
          },
          contact: {
            nome: payload.data.nome,
            email: payload.data.email,
            messaggio: payload.data.messaggio,
          },
          portals_publishing: {
            checked: {
              immoScout: payload.data.immoScout,
              homeGate: payload.data.homeGate,
            },
          },
        },
      };
    case PUBLISH_ON_PORTALS_VALUE_CHANGED:
      if (payload.portal === "homegate") {
        return {
          ...state,
          ad: {
            ...state.ad,
            portals_publishing: {
              ...state.ad.portals_publishing,
              checked: {
                ...state.ad.portals_publishing.checked,
                homeGate: payload.value,
              },
            },
          },
        };
      } else if (payload.portal === "immoscout") {
        return {
          ...state,
          ad: {
            ...state.ad,
            portals_publishing: {
              ...state.ad.portals_publishing,
              checked: {
                ...state.ad.portals_publishing.checked,
                immoScout: payload.value,
              },
            },
          },
        };
      }
      break;
    default:
      return state;
  }
};

export default formsReducer;
