import axios from "axios";
import retry, { catcher } from "./helpers";
import configuration from "../configs/config.json";

const { http } = configuration;

const client = axios.create({
  baseURL: http.baseUrl,
  headers: http.headers,
});

export default {
  post: async ({ id, reference, url, tipologia }) => {
    console.log("LINK", id)
    const data = {
      id: id,
      tour360Url: url,
      tour360: [
        {
          externalReference: reference,
          immobileId: id,
          // fileName: '',
          docType: "image",
          mimeType: "image/jpeg",
          url: url,
          // titolo: '',
          // descrizione: '',
          tipologia,
          dataUpload: new Date(),
          old: false,
        },
      ],
    };
    console.log("LINK", data, id)
    return await retry(client.post, "/immobile/tour360", data);

  },

  delete: async (id) => {
    const data = {
      id: id,
      tour360Url: null,
      tour360: [],
    };
    return await retry(client.post, "/immobile/tour360", data);
  },

  publish: async ({ headers, formData, setHometourProgress }) => {
    const options = {
      ...headers,
      onUploadProgress: setHometourProgress,
    };
    return await axios.post(
      "https://360home.ch/it/api/v1/object_insert",
      formData,
      options
    );
  },

  deleteOnPortal: async (headers, id) => {
    const options = {
      ...headers,
    };
    return await catcher(
      axios.post,
      `https://360home.ch/it/api/v1/object_delete/${id}`,
      {},
      false,
      options
    );
  },

  getCsfr: async () => {
    const csfr = await axios.get("https://www.360home.ch/it/api/v1/csfr");
    const headers = {
      headers: {
        "X-CSRF-TOKEN": csfr.data.csfr,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    };
    return headers;
  },
};
